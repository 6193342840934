<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/contilt-small.png')"
          class="mr-1"
          width="140"
          alt="Toast image"
        />
      </b-link>
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Welcome To Contilt </b-card-title>
          <b-card-text class="mb-2">
            Generate Content Easely and effortless
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="!isPasswordValid" class="text-danger">{{
                    validatePassword
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="!isPasswordValid"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      userEmail: "",
      password: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
      token: "",
    };
  },
  created() {
    this.userEmail = this.$router.currentRoute.query.email;
    this.token = this.$router.currentRoute.query.token;
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    validatePassword() {
      if (this.password.length < 8) {
        return "Your password must be at least 8 characters";
      }
      if (this.password.search(/[a-z]/i) < 0) {
        return "Your password must contain at least one letter.";
      }
      if (this.password.search(/[0-9]/) < 0) {
        return "Your password must contain at least one digit.";
      }
      return "";
    },
    isPasswordValid() {
      const isContainsNumber = /^(?=.*[0-9]).*$/;
      const isValidLength = /^.{10,255}$/;
      if (
        !isContainsNumber.test(this.password) ||
        this.password.search(/[a-z]/i) < 0 ||
        !isValidLength.test(this.password)
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          let data = {
            email: this.userEmail,
            token: this.token,
            password: this.password,
          };
          useJwt
            .register(data)
            .then((response) => {
              if (this.$router.currentRoute.query.token) {
                this.$router.push("/");
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Account Activated",
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Now you can connect with the newly created account `,
                  },
                });
                return;
              }

              useJwt.setToken(response.data.accessToken);
              useJwt.setRefreshToken(response.data.refreshToken);
              localStorage.setItem(
                "userData",
                JSON.stringify(response.data.userData)
              );
              this.$ability.update(response.data.userData.ability);
              this.$router.push("/");
            })
            .catch((error) => {
              this.$refs.registerForm.setErrors(error.response.data.error);
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Error happened",
                  icon: "AlertTriangleIcon",
                  variant: "light-danger",
                  text: `Something went wrong`,
                },
              });
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
